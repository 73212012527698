import config from 'Data';
import React from 'react';
import { compose } from 'recompose';
import { Form, FastField, ErrorMessage, withFormik } from 'formik';
import * as Yup from 'yup';
import Recaptcha from 'react-google-recaptcha';
import { navigate } from 'gatsby';
import { ThemeContext } from 'Common';
import {
  Button,
  Collapse,
  Jumbotron,
  Container,
  Row,
  Col,
  H1,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown
} from 'reactstrap';
import { Wrapper, InputField, Center, Error, Text } from 'Theme/styles';

const RequestForm = ({ errors, touched, setFieldValue, isSubmitting }) => (
  <ThemeContext.Consumer>
    {({ theme }) => (
      <div>
        <Container className="py-5">
          <Row>
            <Col xs="12" sm="11" md="9" lg="8" className="mx-auto">
              <Text theme={theme}>
                Fill in the form below and we'll get back to you
              </Text>
              <Text as="p" theme={theme} />
              <Form
                method="post"
                data-netlify="true"
                data-netlify-recaptcha="true"
                data-netlify-honeypot="bot-field"
              >
                <noscript>
                  <p>This form won’t work with Javascript disabled</p>
                </noscript>
                <Wrapper theme={theme}>
                  <label
                    className="w-100"
                    htmlFor="firstName"
                    aria-label="Please enter first name"
                  >
                    First name:
                    <InputField
                      component="input"
                      as={FastField}
                      type="text"
                      error={touched.firstName && errors.firstName ? 1 : 0}
                      name="firstName"
                    />
                  </label>
                  <ErrorMessage component={Error} name="firstName" />
                </Wrapper>
                <Wrapper theme={theme}>
                  <label
                    className="w-100"
                    htmlFor="lastName"
                    aria-label="Please enter last name"
                  >
                    Last name:
                    <InputField
                      component="input"
                      as={FastField}
                      type="text"
                      error={touched.lastName && errors.lastName ? 1 : 0}
                      name="lastName"
                    />
                  </label>
                  <ErrorMessage component={Error} name="lastName" />
                </Wrapper>
                <Wrapper theme={theme}>
                  <label
                    className="w-100"
                    htmlFor="phone"
                    aria-label="Please enter phone number"
                  >
                    Phone:
                    <InputField
                      component="input"
                      as={FastField}
                      type="tel"
                      error={touched.phone && errors.phone ? 1 : 0}
                      name="phone"
                    />
                  </label>
                  <ErrorMessage component={Error} name="phone" />
                </Wrapper>
                <Wrapper theme={theme}>
                  <label
                    className="w-100"
                    htmlFor="email"
                    aria-label="Please enter email"
                  >
                    Your email:
                    <InputField
                      component="input"
                      as={FastField}
                      type="email"
                      error={touched.email && errors.email ? 1 : 0}
                      name="email"
                    />
                  </label>
                  <ErrorMessage component={Error} name="email" />
                </Wrapper>
                <FastField
                  component={Recaptcha}
                  sitekey="6Lcs6lQUAAAAAEwhNH2IsobIe2csdda4TU3efpMN"
                  name="recaptcha"
                  onChange={value => setFieldValue('recaptcha', value)}
                />
                <ErrorMessage component={Error} name="recaptcha" />
                <Center>
                  <Button
                    color="primary"
                    className="mt-3 btn-block"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Send
                  </Button>
                </Center>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    )}
  </ThemeContext.Consumer>
);

const enhance = compose(
  withFormik({
    mapPropsToValues: () => ({
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      recaptcha: ''
    }),
    validationSchema: () => Yup.object().shape({
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        phone: Yup.string().required('Phone number is required'),
        email: Yup.string()
          .email('Please enter a valid email!')
          .required('Email is required!'),
        recaptcha: Yup.string().required('Robots are not welcome yet!')
      }),
    handleSubmit: async (
      { firstName, lastName, phone, email, recaptcha },
      { setSubmitting, resetForm }
    ) => {
      try {
        const encode = data => {
          return Object.keys(data)
            .map(
              key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
            )
            .join('&');
        };
        await fetch('/?no-cache=1', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': 'request',
            firstName,
            lastName,
            phone,
            email,
            'g-recaptcha-response': recaptcha
          })
        });
        setSubmitting(false);
        resetForm();
        navigate('/thanks/');
      } catch (err) {
        setSubmitting(false);
        alert("Something went wrong, please try again!"); // eslint-disable-line
      }
    }
  })
);

export default enhance(RequestForm);
