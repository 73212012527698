import config from 'Data';
import React from 'react';
import { Layout, Head } from 'Common';
import { Hero } from 'Components/RequestPage/Hero';
import RequestForm from 'Components/RequestPage/RequestForm';

const Request = () => (
  <Layout>
    <Head type="Organization" location="/request">
      Request a Consultation | {config.legalName}
    </Head>
    <Hero />
    <RequestForm />
  </Layout>
);
export default Request;
