import React from 'react';
import { Link } from 'gatsby';
import { FullContainer, ThemeContext } from 'Common';

import { Container, Row, Col } from 'reactstrap';
import { HeroSection } from 'Theme/styles';
import HeroBG from 'Static/background/consultation.jpg';

const Hero = () => (
  <ThemeContext.Consumer>
    {({ theme }) => (
      <FullContainer>
        <HeroSection className="text-md-center" bg={HeroBG}>
          <Container theme={theme}>
            <Row>
              <Col>
                <h1>Request a Consultation</h1>
              </Col>
            </Row>
          </Container>
        </HeroSection>
      </FullContainer>
    )}
  </ThemeContext.Consumer>
);

export { Hero };
